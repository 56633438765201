html {
  font-size: 100%;
}

body {
  font-size: 16px;
  font-size: 1rem;
}

body {
  line-height: 1.75;
  background: #eee;
  font-size: 16px;
  font-size: 1rem;
}

@media screen and (max-width: 979px) {
  body {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

/*-----------------------------------------------
 __layout
-----------------------------------------------*/
#wrap {
  min-height: 100%;
  max-height: 100%;
}

#contents {
  width: 100%;
  position: relative;
}

.row {
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  text-align: left;
  position: relative;
  padding: 0 5%;
  box-sizing: border-box;
}

.row.txt_ac {
  text-align: center;
}

.dnon {
  display: none;
}

.loading_slide {
  position: absolute;
  top: 200px;
  left: 50%;
  z-index: 99;
  margin-left: -22px;
}

/*-----------------------------------------------
 __header
-----------------------------------------------*/
.header_global {
  max-width: 1600px;
  margin: 0 auto;
}

.header_global .main_logo {
  padding: 2em 0 2em 5%;
  box-sizing: border-box;
}

@media screen and (max-width: 660px) {
  .header_global .main_logo {
    padding: 0;
    text-align: center;
    padding: 2em 0;
  }
}

.header_global .logo {
  text-decoration: none;
  font-family: 'Roboto', sans-serif;
  font-weight: 300;
  text-rendering: auto;
  color: #555;
  white-space: nowrap;
  font-size: 30px;
}

.header_global .logo:hover {
  letter-spacing: 0.05em;
  transition: all 0.3s linear;
}

@media screen and (max-width: 660px) {
  .header_global .logo {
    font-size: 24px;
  }
}

.header_global .tax {
  padding-left: 1em;
  color: #999;
  font-size: 14px;
  font-size: 0.875rem;
}

@media screen and (max-width: 660px) {
  .header_global .tax {
    display: block;
    padding-left: 0;
  }
}

.header_global .tax .icn_tag {
  padding-right: .5em;
  color: #00A0E9;
}

/*-----------------------------------------------
 __footer
-----------------------------------------------*/
#footer_global {
  padding: 2em 0 2em 0;
  background: #eee;
}

#footer_global .footer_nav {
  text-align: center;
  padding-bottom: 2em;
}

#footer_global .footer_nav .nav_item {
  padding: 0 1em;
}

#footer_global .footer_nav .nav_item a {
  text-decoration: none;
  color: #777;
  padding: .2em;
}

#footer_global .footer_nav .nav_item a:hover {
  color: #00A0E9;
  transition: all 0.5s linear;
}

#footer_global .ft_inner {
  max-width: 1600px;
  margin: 0 auto;
}

#copyright {
  position: relative;
  font-size: 12px;
  color: #aaa;
  text-align: center;
  padding-top: 3em;
}

#copyright a {
  color: #aaa;
  text-decoration: none;
}

#copyright a:hover {
  color: #aaa;
}

#copyright .line {
  position: absolute;
  top: 0;
  left: 50%;
  width: 150px;
  height: 2px;
  margin-left: -75px;
  background: #00A0E9;
}

.about {
  display: none;
}

.about .inner {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  background: #fff;
  background: rgba(255, 255, 255, 0.5);
  box-sizing: border-box;
}

.about .about_img {
  max-width: 100%;
}

.about .company_info {
  margin: 0 auto;
  padding: 5%;
  width: 100%;
  box-sizing: border-box;
  font-size: 14px;
  font-size: 0.875rem;
  overflow: hidden;
}

@media screen and (max-width: 660px) {
  .about .company_info {
    font-size: 11px;
    font-size: 0.6875rem;
  }
}

.about .company_info dt {
  float: left;
  clear: left;
  width: 20%;
  box-sizing: border-box;
  padding: 1% 2%;
  color: #00A0E9;
  text-align: right;
}

.about .company_info dd {
  float: left;
  width: 80%;
  box-sizing: border-box;
  padding: 1% 2%;
  text-align: left;
}

.about .company_info dd.small {
  font-size: 12px;
  font-size: 0.75rem;
}

@media screen and (max-width: 660px) {
  .about .company_info dd.small {
    font-size: 10px;
    font-size: 0.625rem;
  }
}

.about .company_info .callto {
  text-decoration: none;
  display: inline-block;
  background: #00A0E9;
  color: #fff;
  padding: 0.5em 1em;
  line-height: 1em;
  border-radius: 1em;
}

/*-----------------------------------------------
 __main
-----------------------------------------------*/
.works_contents {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 80px;
}

.works_item_wrap {
  width: 100%;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
}

.works_item_wrap .works_item {
  width: 33.3%;
  box-sizing: border-box;
  padding: 20px;
  display: inline-block;
  vertical-align: top;
  transition: all 0.5s linear;
  opacity: 0;
}

@media screen and (max-width: 1023px) {
  .works_item_wrap .works_item {
    width: 50%;
  }
}

@media screen and (max-width: 660px) {
  .works_item_wrap .works_item {
    width: 100%;
  }
}

.works_item_wrap .item_box {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
}

.works_item_wrap .img_wrap .works_thumb_img {
  max-width: 100%;
}

.works_item_wrap .txt_contents {
  background: #fff;
  padding: 1.5em 1em;
  position: relative;
  box-sizing: border-box;
}

.works_item_wrap .txt_contents .entry_ttl {
  font-size: 15px;
  font-size: 0.9375rem;
  line-height: 1;
  white-space: nowrap;
  overflow: hidden;
}

.works_item_wrap .txt_contents .entry_ttl a {
  text-decoration: none;
  color: #555;
}

.works_item_wrap .txt_contents .term {
  padding-top: 1.4em;
  font-size: 10px;
  font-size: 0.625rem;
  color: #999;
}

.works_item_wrap .txt_contents .term .cat_tag {
  padding: 0 0.5em;
  vertical-align: bottom;
}

.works_item_wrap .txt_contents .term .icn_tag {
  font-size: 16px;
  color: #ddd;
  vertical-align: middle;
}

.works_item_wrap .txt_contents .exticn_link {
  text-decoration: none;
  color: #999;
}

.works_item_wrap .txt_contents .exticn_link:hover {
  color: #00A0E9;
}

.works_item_wrap .txt_contents .exticn {
  position: absolute;
  right: 1em;
  bottom: 1em;
  font-size: 12px;
  color: #999;
}

.works_item_wrap .txt_contents .exticn:hover {
  color: #00A0E9;
}

.infinite_btn {
  padding-top: 2em;
  text-align: center;
}

.infinite_btn a {
  font-size: 14px;
  display: inline-block;
  border: 1px solid #00A0E9;
  padding: 1em 3em;
  line-height: 1;
  text-decoration: none;
  color: #00A0E9;
  border-radius: 2px;
}

.infinite_btn a:hover {
  background-color: #00A0E9;
  color: #fff;
}

#infscr-loading {
  position: absolute;
  left: 50%;
  margin-left: -100px;
  bottom: -30px;
  width: 200px;
  color: #ccc;
  box-sizing: border-box;
  text-align: center;
}

#infscr-loading img {
  text-align: center;
  display: block;
  margin: 0 auto;
}

.noscript_outer {
  display: none;
  position: absolute;
  font-size: 12px;
  z-index: 99999;
  left: 0;
  top: 0;
  border-bottom: 1px solid #eee;
  background: #fafafa;
  padding: 1em 5%;
  width: 100%;
  text-align: center;
  color: #4AA3DF;
  box-sizing: border-box;
}

body.single_works {
  background: #6C7A89;
}

body.single_works.lblue {
  background: #6BB9F0;
}

body.single_works.dblue {
  background: #446CB3;
}

body.single_works.green {
  background: #1BA39C;
}

body.single_works.orange {
  background: #F89406;
}

body.single_works.yellow {
  background: #F4D03F;
}

body.single_works.dgrey {
  background: #6C7A89;
}

body.single_works.lgrey {
  background: #D2D7D3;
}

body.single_works.purple {
  background: #9B59B6;
}

body.single_works.red {
  background: #D91E18;
}

body.single_works.pink {
  background: #D2527F;
}

body.single_works .header_global .logo {
  color: #fff;
}

body.single_works #footer_global {
  background: #54504f;
}

.entry_contents {
  position: relative;
}

.entry_contents .img_area {
  padding: 0 0 3em 0;
  opacity: 0;
  transition: all 0.5s linear;
}

.entry_contents .img_wrap {
  text-align: center;
  margin: 0 auto;
  width: 100%;
  box-sizing: border-box;
}

.entry_contents .img_wrap .item {
  padding: 0 5%;
  box-sizing: border-box;
  text-align: center;
}

.entry_contents .img_wrap .works_img {
  display: block;
  margin: 0 auto;
  width: 100%;
  max-width: 900px;
}

.entry_contents .img_wrap .works_img.responsive {
  max-width: 1000px;
}

.entry_contents .txt_contents {
  padding: 4em 0 5em 0;
  background: #eee;
}

.entry_contents .entry_ttl {
  text-align: center;
  font-size: 30px;
  font-size: 1.875rem;
  padding-bottom: .5em;
}

@media screen and (max-width: 660px) {
  .entry_contents .entry_ttl {
    font-size: 18px;
    font-size: 1.125rem;
  }
}

.entry_contents .term {
  text-align: center;
  color: #999;
  font-size: 12px;
  padding-bottom: 1.5em;
  text-align-last: left;
  word-break: break-all;
}

.entry_contents .term .cat_tag {
  padding: 0 0.5em;
}

.entry_contents .term .icn_tag {
  color: #ddd;
}

.entry_contents .term a {
  color: #999;
  text-decoration: none;
}

.entry_contents .term a:hover {
  color: #00A0E9;
}

.entry_contents .view_site {
  padding: 2em 0 0 0;
  text-align: center;
}

.entry_contents .view_site .sitelink {
  text-decoration: none;
  color: #00A0E9;
  letter-spacing: .1em;
  padding: .5em 0;
  border-bottom: 2px solid #00A0E9;
}

.entry_contents .view_site .sitelink:hover {
  padding: 0.5em 1em;
  transition: all 0.2s linear;
}

.ctrl_original {
  padding-top: 1em;
}

.ctrl_original .dots_original {
  margin: 0 auto;
  text-align: center;
}

.ctrl_original .dots_original .dot_original {
  background: #bbb;
  width: 12px;
  height: 12px;
  border-radius: 6px;
  display: inline-block;
  margin: 0 6px;
  cursor: pointer;
}

.ctrl_original .dots_original .dot_original.active {
  background: #ddd;
}

.ctrl_original .dots_original .dot_original:only-child {
  display: none;
}
